@font-face {
font-family: '__sofiapro_097fac';
src: url(https://taxes-5tgbicg2f.purposity.app/_next/static/media/2f3ffd6913e347e8-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__sofiapro_Fallback_097fac';src: local("Arial");ascent-override: 67.81%;descent-override: 21.72%;line-gap-override: 5.41%;size-adjust: 105.45%
}.__className_097fac {font-family: '__sofiapro_097fac', '__sofiapro_Fallback_097fac';font-weight: 700;font-style: normal
}.__variable_097fac {--font-sofiapro: '__sofiapro_097fac', '__sofiapro_Fallback_097fac'
}

@font-face {
font-family: '__gilroy_50eb7e';
src: url(https://taxes-5tgbicg2f.purposity.app/_next/static/media/4f9d35a96b77d8f8-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gilroy_50eb7e';
src: url(https://taxes-5tgbicg2f.purposity.app/_next/static/media/c2d4c9c2542b905c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__gilroy_50eb7e';
src: url(https://taxes-5tgbicg2f.purposity.app/_next/static/media/9cda8d25de73986b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__gilroy_Fallback_50eb7e';src: local("Arial");ascent-override: 88.14%;descent-override: 23.02%;line-gap-override: 0.00%;size-adjust: 104.36%
}.__className_50eb7e {font-family: '__gilroy_50eb7e', '__gilroy_Fallback_50eb7e'
}.__variable_50eb7e {--font-gilroy: '__gilroy_50eb7e', '__gilroy_Fallback_50eb7e'
}

@font-face {
font-family: '__sourcesanspro_76366f';
src: url(https://taxes-5tgbicg2f.purposity.app/_next/static/media/10357e89acf108be-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__sourcesanspro_76366f';
src: url(https://taxes-5tgbicg2f.purposity.app/_next/static/media/a736140e6931627e-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__sourcesanspro_76366f';
src: url(https://taxes-5tgbicg2f.purposity.app/_next/static/media/1fd6def6d58799d2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__sourcesanspro_Fallback_76366f';src: local("Arial");ascent-override: 104.47%;descent-override: 28.98%;line-gap-override: 0.00%;size-adjust: 94.19%
}.__className_76366f {font-family: '__sourcesanspro_76366f', '__sourcesanspro_Fallback_76366f'
}.__variable_76366f {--font-sourcesanspro: '__sourcesanspro_76366f', '__sourcesanspro_Fallback_76366f'
}

